@import './Palette.scss';

@mixin font-face($style-name, $file, $weight) {
    $filepath: "/fonts/" + $file;
    @font-face {
        font-family: quote($style-name);
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
        url($filepath + ".woff") format('woff'),
        url($filepath + ".ttf") format('truetype'),
        url($filepath + ".svg#" + $style-name + "") format('svg');
        font-weight: $weight;
        font-style: normal;
    }
}

@include font-face('Montserrat', 'Montserrat-Regular', 400);
@include font-face('Montserrat', 'Montserrat-Light', 300);
@include font-face('Montserrat', 'Montserrat-Bold', 700);

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

:focus {
    outline: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
    font-family: 'Montserrat', sans-serif;
    margin: 0 auto;
    padding: 0;
    font-weight: 400;
    color: $dark;
}


.app-wrapper {
    min-height: 100vh;
    background-color: $light;
}