@import '../styles/Palette.scss';

.container {
    margin: 0 auto;
    padding: 3rem 1rem 3rem;

    display: flex;
    flex-direction: column;

    @media (min-width: 824px) {
        width: 768px;
    }

    @media (max-width: 768px) {
        padding: 1rem;
    }

    @media (min-width: 1280px) {
        width: 1104px ;
    }

}

.mobile__container {
    @media (max-width: 768px) {
        // height: 100vh;
    }
}

.menu {
    padding: 1rem 0;

    &.sticky {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: $light;

        @media (max-width: 768px) {
            padding: 0;

            & > * {
                height: 3rem;
            }
        }
    }

    & > * {
        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            align-items: center;
            height: 4rem;
        }

        @media (max-height: 715px) {
            height: 1rem; 
        }
    }   

    &__item {
        text-decoration: none;
        color: $dark;
        cursor: pointer;
        
        &:visited,
        &:focus,
        &:hover,
        &:active {
            color: $dark;
            text-decoration: none;
        }

        &:not(:last-child) {
            margin-right: 2rem;            
            @media (max-width: 768px) {
                margin-right: 1rem;
            }
        }

    }
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 7rem);
    padding: 1rem 0 5rem;
    padding-bottom: 0;

    @media (max-height: 715px) {
        padding: 0
    }

    &__header {
        &-title {
            font-size: 15rem;
            margin-bottom: 2rem;

            background: linear-gradient(90deg, rgba(38,38,38,1) 50%, rgba(246,246,246,1) 99%);
            color: #f6f6f6;

            @media (max-width: 768px) {
                font-size: 5rem;
            }
        }
    
        &-subtitle {
            font-size: 2rem;

            @media (max-width: 768px) {
                font-size: 1.2rem;
                margin-bottom: 3rem;
            }
        }
    }

    &__content {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        text-align: justify;
        font-weight: lighter;
        line-height: 1.6rem;

        @media (max-width: 768px) {
            font-size: 1rem;
            line-height: 1.2rem;
        }

        &-epilogue {
            font-size: 1.2rem;
            font-style: italic;
            margin: 3rem 0;
        }
    }
}

.service {
    height: inherit;
    padding: 1rem 0;

    @media (max-width: 767px) {
        padding: 1rem 0;
    }

    &.__contact {
        color: $light;
        background-color: $dark;
        border-radius: 1rem;
        font-weight: lighter;
        margin-top: 1rem;
        padding: 1rem;

        & > .service__content:not(:last-child) {
            padding-bottom: 0.5rem;
        }
    }

    &__title {
        font-size: 1.5rem;
        margin-bottom: 1.2rem;

        @media (max-width: 767px) {
            // font-size: 2rem;
        }
    }

    &__content {
        line-height: 1.6rem;
        padding-left: 3rem;
        overflow-wrap: break-word;
        text-align: justify;

        @media (max-width: 767px) {
            font-size: 0.9rem;
            padding-left: 0;
        }
    }
}

.hiring {
    display: block;

    &__title {
        font-size: 1.8rem;
        padding: 1.5rem;
        text-align: center;
        margin-bottom: 1rem;
    }
}

.skills {
    &__list {
        padding-left: 3rem;
    }

    &__title {
        margin: 1rem 0;
    }
}

.text-bold {
    font-weight: 600;
}